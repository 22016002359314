<template>

  <div class="page-content">
    <div class="container-fluid">

      <Breadcrumb
          :pgTitle="$t('nav.appSettings')"
          :pgIcon="'bx bx-flag'"
          :refs="refs"
          :pgCurrent="pgCurrent"
          :addNew="false">
      </Breadcrumb>

      <div class="row">

        <Loading
            v-if="pgLoading">
        </Loading>

        <div v-if="!pgLoading" class="col-8">

          <div class="card">
            <div class="card-body">

              <div class="tab-danger pt-3">

                <!-- Nav Tabs -->
                <ul v-if="$i18n.locale == 'ar'" class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab-arabic" class="nav-link" role="tab" data-toggle="tab" aria-selected="true"
                       :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15 active show' : ''">
                      {{ $t('app.arabic') }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#tab-english" class="nav-link" role="tab" data-toggle="tab" aria-selected="false"
                       :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'active show'">
                      {{ $t('app.english') }}
                    </a>
                  </li>
                </ul>
                <ul v-if="$i18n.locale == 'en'" class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab-english" class="nav-link" role="tab" data-toggle="tab" aria-selected="false"
                       :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'active show'">
                      {{ $t('app.english') }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#tab-arabic" class="nav-link" role="tab" data-toggle="tab" aria-selected="true"
                       :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15 active show' : ''">
                      {{ $t('app.arabic') }}
                    </a>
                  </li>
                </ul>
                <!-- End Nav Tabs -->

                <!-- Tab Panels -->
                <div class="tab-content">
                  <div
                      role="tabpanel"
                      class="tab-pane fade pt-3"
                      :class="($i18n.locale == 'ar') ? 'active show' : ''"
                      id="tab-arabic">

                    <!-- Title -->
                    <div class="form-group col-12">
                      <label
                          for="inputAR1"
                          class="col-form-label"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                        {{ $t('view.app_name') }}
                      </label>
                      <input
                          id="inputAR1"
                          type="text"
                          class="form-control"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                          v-model="row.title_ar">
                    </div>
                    <!-- End Title -->

                    <!-- Body -->
                    <div class="form-group col-12">
                      <label
                          for="editorAR1"
                          class="col-form-label"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                        {{ $t('view.mission') }}
                      </label>
                      <div v-if="$i18n.locale == 'en'" class="col-12"><br/><br/></div>
                      <editor id="editorAR1"
                              v-model="row.mission_ar"
                              :api-key="editor.api_key"
                              :init="{
                                        height: 300,
                                        menubar: editor.menubar,
                                        plugins: editor.plugins,
                                        toolbar: editor.toolbar
                                    }"
                      />
                    </div>
                    <!-- End Body -->

                    <!-- Body -->
                    <div class="form-group col-12">
                      <label
                          for="editorAR2"
                          class="col-form-label"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                        {{ $t('view.goal') }}
                      </label>
                      <div v-if="$i18n.locale == 'en'" class="col-12"><br/><br/></div>
                      <editor id="editorAR2"
                              v-model="row.goal_ar"
                              :api-key="editor.api_key"
                              :init="{
                                        height: 300,
                                        menubar: editor.menubar,
                                        plugins: editor.plugins,
                                        toolbar: editor.toolbar
                                    }"
                      />
                    </div>
                    <!-- End Body -->

                    <!-- Title -->
                    <div class="form-group col-12">
                      <label
                          for="inputAR4"
                          class="col-form-label"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                        {{ $t('view.address') }}
                      </label>
                      <input
                          id="inputAR4"
                          type="text"
                          class="form-control"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                          v-model="row.address_ar">
                    </div>
                    <!-- End Title -->

                  </div>

                  <div
                      role="tabpanel"
                      class="tab-pane fade in pt-3"
                      :class="($i18n.locale == 'ar') ? '' : 'active show'"
                      id="tab-english">

                    <!-- Title -->
                    <div class="form-group col-12">
                      <label
                          for="inputAR1"
                          class="col-form-label"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                        {{ $t('view.app_name') }}
                      </label>
                      <input
                          id="inputAR3"
                          type="text"
                          class="form-control"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                          v-model="row.title_en">
                    </div>
                    <!-- End Title -->

                    <!-- Body -->
                    <div class="form-group col-12">
                      <label
                          for="editorAR1"
                          class="col-form-label"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                        {{ $t('view.mission') }}
                      </label>
                      <div v-if="$i18n.locale == 'en'" class="col-12"><br/><br/></div>
                      <editor id="editorAR4"
                              v-model="row.mission_en"
                              :api-key="editor.api_key"
                              :init="{
                                        height: 300,
                                        menubar: editor.menubar,
                                        plugins: editor.plugins,
                                        toolbar: editor.toolbar
                                    }"
                      />
                    </div>
                    <!-- End Body -->

                    <!-- Body -->
                    <div class="form-group col-12">
                      <label
                          for="editorAR2"
                          class="col-form-label"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                        {{ $t('view.goal') }}
                      </label>
                      <div v-if="$i18n.locale == 'en'" class="col-12"><br/><br/></div>
                      <editor id="editorAR5"
                              v-model="row.goal_en"
                              :api-key="editor.api_key"
                              :init="{
                                        height: 300,
                                        menubar: editor.menubar,
                                        plugins: editor.plugins,
                                        toolbar: editor.toolbar
                                    }"
                      />
                    </div>
                    <!-- End Body -->

                    <!-- Title -->
                    <div class="form-group col-12">
                      <label
                          for="inputAR6"
                          class="col-form-label"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                        {{ $t('view.address') }}
                      </label>
                      <input
                          id="inputAR4"
                          type="text"
                          class="form-control"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                          v-model="row.address_en">
                    </div>
                    <!-- End Title -->


                  </div>
                </div>
                <!-- End Tab Panels -->

              </div>
            </div>
          </div>


        </div>

        <div v-if="!pgLoading" class="col-4">

          <div class="card">
            <div class="card-body">
              <!-- Email -->
              <div class="form-group col-12">
                <label
                    for="input4"
                    class="col-form-label"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                  {{ $t('view.email') }}
                </label>
                <input
                    id="input4"
                    type="email"
                    autocomplete="off"
                    class="form-control"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                    v-model="row.email"
                    required="">
              </div>
              <!-- End Email -->

              <!-- Mobile -->
              <div class="form-group col-12">
                <label
                    for="input7"
                    class="col-form-label"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                  {{ $t('view.mobile') }}
                </label>
                <input
                    id="input7"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                    v-model="row.phone"
                    required="">
              </div>
              <!-- End Mobile -->
              <!-- video_link -->
              <div class="form-group col-12">
                <label
                    for="input7"
                    class="col-form-label"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : 'float-left'">
                  {{ $t('view.video_link') }}
                </label>
                <input
                    id="input7"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                    v-model="row.video_link"
                    required="">
              </div>
              <!-- End video_link -->
            </div>

            <CardImage
                :labelImage="$t('view.image')"
                :image_base64="row.image_base64"
                :image_preview="row.image_preview"

                v-on:imageChange="handleImageChange">
            </CardImage>

          </div>
        </div>
      </div>


      <Buttons
          v-if="!pgLoading"
          :btnLoading="btnLoading"
          :btnCurrent="btnCurrent"

          v-on:submitClicked="handelSubmit"
          v-on:cancelClicked="handleCancel">
      </Buttons>

    </div>
  </div>

</template>

<script>
import iziToast from 'izitoast';
import CardImage from "@/components/CardImage";

export default {
  name: 'EditOrNew',
  components: {
    CardImage,
    Breadcrumb: () => import('@/components/Breadcrumb.vue'),
    Loading: () => import('@/components/Loading.vue'),
    Buttons: () => import('@/components/Buttons.vue'),
    Editor: () => import('@tinymce/tinymce-vue')
  },
  data() {
    return {
      // auth
      auth: {
        role: '',
        access_token: '',
      },

      // row
      row: {
        image_base64: '',
        image_preview: '',

        title_ar: '',
        title_en: '',

        goal_ar: '',
        goal_en: '',

        mission_ar: '',
        mission_en: '',

        address_ar: '',
        address_en: '',

        video_link: '',
        email: '',
        phone: ''

      },
      editor: {
        api_key: window.editor_apiKey,
        menubar: window.editor_menubar,
        plugins: window.editor_plugins,
        toolbar: window.editor_toolbar,
      },
      msgCurrent: 'Updated',
      btnCurrent: 'Update',
      methodType: 'PUT',
      pgLoading: true,
      pgCurrent: 'Edit',
      btnLoading: false,

      refs: 'app_settings',
    }
  },
  mounted() {
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem('access_token')) {
      this.auth.access_token = localStorage.getItem('access_token');
    }
    if (localStorage.getItem('role')) {
      this.auth.role = localStorage.getItem('role');
    }
    this.fetchRow();
  },
  methods: {

    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + '/' + this.refs,
        method: 'GET',
        data: {}
      }
      this.axios(options)
          .then(res => {
            this.pgLoading = false;

            this.row.image_preview = res.data.row.logo.url,

                this.row.title_ar = res.data.row.ar.title;
            this.row.title_en = res.data.row.en.title;

            this.row.mission_ar = res.data.row.ar.mission;
            this.row.mission_en = res.data.row.en.mission;

            this.row.goal_ar = res.data.row.ar.goal;
            this.row.goal_en = res.data.row.en.goal;

            this.row.address_ar = res.data.row.ar.address;
            this.row.address_en = res.data.row.en.address;

            this.row.phone = res.data.row.phone;
            this.row.email = res.data.row.email;
            this.row.video_link = res.data.row.video_link;

          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                title: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }

          })
          .finally(() => {
          });
    },


    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };

      const config = {headers: {'Content-Type': 'multipart/form-data'}};
      const options = {
        url: window.baseURL + '/' + this.refs,
        method: this.methodType,
        data: {

          ar: {
            title: this.row.title_ar,
            mission: this.row.mission_ar,
            goal: this.row.goal_ar,
            address: this.row.address_ar,
          },

          en: {
            title: this.row.title_en,
            mission: this.row.mission_en,
            goal: this.row.goal_en,
            address: this.row.address_en,
          },

          image_base64: this.row.image_base64,
          video_link: this.row.video_link,
          email: this.row.email,
          phone: this.row.phone
        }
      }
      this.axios(options, config)
          .then(() => {
            this.btnLoading = false;
            iziToast.success({
              icon: 'bx bx-wink-smile',
              title: '',
              message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
            });
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                title: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }
          })
          .finally(() => {
          })

    },


    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('avatar');
      localStorage.removeItem('username');
      localStorage.removeItem('encrypt_id');
      localStorage.removeItem('role');
    },


    // Cancel
    handleCancel() {
      if (confirm('هل انت متأكد؟')) {
        this.$router.push({name: this.refs});
      }
    },


    // handel Changes from child components
    handleImageChange(event) {
      this.row.image_base64 = event.image_base64;
    },

  },
}
</script>
